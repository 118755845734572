<template>
    <div class="content">
        <BreadCrumbs :elements="[{ link: 'Cart', name: 'Kosár' }]" />
        <CartHeader />
        <section class="py-5">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h1 class="title text-center">Kosár</h1>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="container">
                <div
                    class="row align-items-center border-bottom pb-3 d-none d-md-flex"
                >
                    <div class="col-md-6">
                        <strong class="text-uppercase">Termék neve</strong>
                    </div>
                    <div class="col-md-2">
                        <strong class="text-uppercase">Ár/Db</strong>
                    </div>
                    <div class="col-md-2">
                        <strong class="text-uppercase">Mennyiség</strong>
                    </div>
                    <div class="col-md-2">
                        <strong class="text-uppercase">Részösszeg</strong>
                    </div>
                </div>
                <template v-for="(item, index) in cart.items">
                    <div
                        class="row align-items-center border-bottom py-3"
                        v-bind:key="'cart-' + index"
                    >
                        <div class="col-md-6">
                            <strong class="text-uppercase"
                                >{{ item.product.cicsopnev }} <br />
                                {{ item.product.cikknev }} <br />
                                {{ item.product.pattern }} <br />
                                {{ item.product.m1nev }}</strong
                            >
                        </div>
                        <div class="col-md-2">
                            <template v-if="item.product.special_price !== 0"
                                >{{
                                    item.product.calculated_special_price
                                }}
                                Ft</template
                            >
                            <template v-else
                                >{{
                                    item.product.calculated_netto
                                }}
                                Ft</template
                            >
                        </div>
                        <div class="col-md-2">
                            <div class="d-flex align-items-center">
                                <input
                                    type="number"
                                    v-model="item.quantity"
                                    min="1"
                                    v-on:change="updateCart"
                                    v-on:keyup="updateCart"
                                    :max="item.product.tat"
                                    class="form-control"
                                    oninput="this.value = Math.abs(this.value)"
                                />
                                <button
                                    class="btn"
                                    v-b-tooltip.hover
                                    title="Törlés"
                                >
                                    <img
                                        src="img/bin.svg"
                                        class="small-icon"
                                        v-on:click="deleteCart(item.id)"
                                    />
                                </button>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <template v-if="item.product.special_price !== 0"
                                >{{
                                    formatNumber(
                                        parseInt(
                                            item.product.calculated_special_price.replace(
                                                " ",
                                                ""
                                            )
                                        ) * item.quantity
                                    )
                                }}
                                Ft</template
                            >
                            <template v-else
                                >{{
                                    formatNumber(
                                        parseInt(
                                            item.product.calculated_netto.replace(
                                                " ",
                                                ""
                                            )
                                        ) * item.quantity
                                    )
                                }}
                                Ft</template
                            >
                        </div>
                    </div>
                </template>
            </div>
        </section>
        <section>
            <div class="container">
                <div class="row my-4">
                    <div class="col-xl-9 mb-4 mb-xl-0">
                        <router-link :to="'/'" class="text-grey d-block"
                            >Vásárlás folytatása</router-link
                        >
                    </div>
                    <div class="col-xl-3" v-if="cart && cart.cart_total">
                        <div class="row">
                            <div class="col-6">
                                Nettó végösszeg:
                            </div>
                            <div class="col-6 text-end">
                                {{ cart.cart_total.total_netto_f }} Ft
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                ÁFA tartalom:
                            </div>
                            <div class="col-6 text-end">
                                {{ cart.cart_total.total_vat_f }} Ft
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <strong class="text-orange">Végösszeg:</strong>
                            </div>
                            <div class="col-6 text-end">
                                <strong class="text-orange"
                                    >{{ cart.cart_total.total_f }} Ft</strong
                                >
                            </div>
                        </div>
                        <button
                            v-if="!loggedIn"
                            type="submit"
                            class="btn btn-primary w-100 my-4"
                            v-b-modal.modal-1
                        >
                            Tovább a pénztárhoz
                        </button>
                        <router-link
                            :tag="'button'"
                            v-else
                            :to="{ name: 'CheckoutBilling' }"
                            class="btn btn-primary w-100 my-4"
                        >
                            Tovább a pénztárhoz
                        </router-link>

                        <b-modal
                            id="modal-1"
                            title=""
                            hide-footer
                            centered
                            size="lg"
                        >
                            <div class="row align-items-center">
                                <div class="col-md-6">
                                    <div class="title-small mb-4 text-center">
                                        Bejelentkezés
                                    </div>
                                    <p class="text-center">
                                        Ha rendelkezel felhasználó fiókkal,
                                        kérlek jelentkezz be.
                                    </p>

                                    <form v-on:submit.prevent="doLogin">
                                        <div class="my-4">
                                            <label>E-mail</label>
                                            <input
                                                type="email"
                                                name="email"
                                                v-model="form.email"
                                                class="form-control"
                                            />
                                        </div>
                                        <div class="my-4">
                                            <label>Jelszó</label>
                                            <input
                                                type="password"
                                                name="password"
                                                v-model="form.password"
                                                class="form-control"
                                            />
                                        </div>

                                        <button
                                            type="submit"
                                            class="btn btn-primary w-100 mb-2"
                                        >
                                            Bejelentkezés
                                        </button>
                                        <router-link
                                            :tag="'button'"
                                            :to="{ name: 'Registration' }"
                                            class="btn btn-third w-100 mb-2"
                                        >
                                            Regisztráció
                                        </router-link>
                                    </form>
                                </div>
                                <div class="col-md-6 text-center">
                                    <div class="title-small mb-4">
                                        Vásárlás vendégként
                                    </div>
                                    <p>
                                        Ha még nincs fiókod érdemes
                                        regisztrálni, mert a későbbiekben sokkal
                                        gyorsabban fogsz tudni vásárolni
                                    </p>
                                    <router-link
                                        :to="{ name: 'CheckoutBilling' }"
                                        class="btn btn-primary mb-2"
                                        >Ezt választom</router-link
                                    >
                                </div>
                            </div>
                        </b-modal>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import BreadCrumbs from "@/components/BreadCrumbs";
import CartHeader from "@/components/CartHeader";

export default {
    name: "Cart",
    components: { CartHeader, BreadCrumbs },
    data() {
        return {
            cart: {},
            form: {
                email: null,
                password: null
            }
        };
    },
    computed: {
        url: function() {
            return process.env.VUE_APP_API_URL;
        },
        loggedIn: function() {
            if ($cookies.get("id_token") && $cookies.get("user")) {
                return true;
            }
            return false;
        }
    },
    beforeMount() {
        this.getCart();
    },
    methods: {
        doLogin() {
            this.$http.post(`${this.url}/login`, this.form).then(response => {
                $cookies.set("id_token", response.data.token, 86400);
                $cookies.set("user", response.data, 86400);

                document.location.href = "/checkout/billing";
            });
        },
        deleteCart(id) {
            const self = this;
            const cart_uuid = $cookies.get("cart_uuid");
            ApiService.delete(
                `${self.url}/cart?uuid=${cart_uuid}&id=${id}`
            ).finally(() => {
                self.getCart();
                self.$eventHub.$emit("updateCart", true);
            });
        },
        updateCart() {
            const cartUuid = $cookies.get("cart_uuid");
            ApiService.post(`${this.url}/cart`, {
                cart: this.cart,
                uuid: cartUuid
            }).then(response => {
                $cookies.set("cart_uuid", response.data.data.uuid, 864000);
                this.cart = response.data.data;
            });
        },
        getCart() {
            const cartUuid = $cookies.get("cart_uuid");
            ApiService.query(`${this.url}/cart?uuid=${cartUuid}`).then(
                response => {
                    $cookies.set("cart_uuid", response.data.data.uuid, 864000);
                    this.cart = response.data.data;
                }
            );
        }
    }
};
</script>

<style scoped></style>
